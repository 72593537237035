// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Agency from "../../../../../routes/common/Routes_Agency.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";

function DashboardAgencyNavbar(props) {
  var agencyName = props.agencyName;
  var agencyId = props.agencyId;
  var __currentLink = props.currentLink;
  var currentLink = __currentLink !== undefined ? __currentLink : "Index";
  var linkCss = function (link) {
    if (link === currentLink) {
      return DashboardNavbarCss.underlinedLinkContainer;
    } else {
      return DashboardNavbarCss.linkContainer;
    }
  };
  var tmp;
  tmp = currentLink === "Index" ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem;
  var tmp$1;
  tmp$1 = currentLink === "New" ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem;
  var tmp$2;
  var exit = 0;
  var x;
  if (agencyId !== undefined) {
    var x$1 = Caml_option.valFromOption(agencyId);
    if (agencyName !== undefined) {
      switch (currentLink) {
        case "Edit" :
            x = x$1;
            exit = 1;
            break;
        case "Index" :
        case "New" :
            tmp$2 = null;
            break;
        case "Show" :
            tmp$2 = JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx(A.make, {
                        href: Routes_Agency.Dashboard.show(x$1),
                        className: DashboardNavbarCss.underlinedLinkItem,
                        children: agencyName
                      }),
                  className: linkCss("Show")
                });
            break;
        
      }
    } else if (currentLink === "Edit") {
      x = x$1;
      exit = 1;
    } else {
      tmp$2 = null;
    }
  } else {
    tmp$2 = null;
  }
  if (exit === 1) {
    tmp$2 = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(A.make, {
                href: Routes_Agency.Dashboard.edit(x),
                className: DashboardNavbarCss.underlinedLinkItem,
                children: "Edit Agency"
              }),
          className: linkCss("Edit")
        });
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Agency.Dashboard.index,
                                  className: tmp,
                                  children: "Agencies"
                                }),
                            className: linkCss("Index")
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Agency.Dashboard.$$new,
                                  className: tmp$1,
                                  children: "New Agency"
                                }),
                            className: linkCss("New")
                          }),
                      tmp$2
                    ],
                    className: DashboardNavbarCss.barContainer
                  }),
              className: DashboardNavbarCss.barWrapper
            });
}

var Css;

var make = DashboardAgencyNavbar;

export {
  Css ,
  make ,
}
/* A Not a pure module */
